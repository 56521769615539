// extracted by mini-css-extract-plugin
export var billetShadow = "project-support-module--billet-shadow--515db";
export var buttonClose = "project-support-module--buttonClose--341af";
export var checkMarked = "project-support-module--checkMarked--72686";
export var checkedList = "project-support-module--checkedList--adb96";
export var colorScheme__background__grayPale = "project-support-module--colorScheme__background__gray-pale--c3607";
export var containerWithBefore = "project-support-module--containerWithBefore--5b5af";
export var expertise = "project-support-module--expertise--71db5";
export var expertise__content = "project-support-module--expertise__content--9f33a";
export var headline = "project-support-module--headline--62cb7";
export var headline_grid = "project-support-module--headline_grid--077cf";
export var hiddenTitle = "project-support-module--hiddenTitle--65d76";
export var icomoon = "project-support-module--icomoon--3dfbe";
export var infoButton = "project-support-module--infoButton--7ff3a";
export var infoButtonWrapper = "project-support-module--infoButtonWrapper--d3505";
export var infoTooltipWrapper = "project-support-module--infoTooltipWrapper--bbb7f";
export var levelCard = "project-support-module--levelCard--3dadb";
export var levelCard__footer = "project-support-module--levelCard__footer--b05d3";
export var levelCard__header = "project-support-module--levelCard__header--35340";
export var lowercase = "project-support-module--lowercase--d6961";
export var projectSupportBlock = "project-support-module--projectSupportBlock--9e5e6";
export var scrollingBackground = "project-support-module--scrollingBackground--8e697";
export var simpleList = "project-support-module--simpleList--19911";
export var supportLevels = "project-support-module--supportLevels--15cd0";
export var supportLevelsContent = "project-support-module--supportLevelsContent--ade9c";
export var terms = "project-support-module--terms--c7b46";
export var terms__image = "project-support-module--terms__image--7477a";
export var terms__text = "project-support-module--terms__text--d9399";
export var tooltip = "project-support-module--tooltip--9a3b0";
export var tooltipArrow = "project-support-module--tooltipArrow--d9ce7";
export var tooltipOpen = "project-support-module--tooltipOpen--48737";
export var transition = "project-support-module--transition--8a96b";
export var transitionBackground = "project-support-module--transition-background--78111";
export var transitionReverse = "project-support-module--transition-reverse--2547c";
export var uppercase = "project-support-module--uppercase--206c5";
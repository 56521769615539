import React, { useState, useRef, useEffect } from "react"
import cx from "classnames"

import * as styles from "../../assets/styles/project-support.module.scss"

const TOOLTIPS = {
  ongoing: {
    title: "Ongoing support",
    text:
      "Works better if your software is live and many people use it. Our clients choose this package when a critical software issue should be addressed by a timely manager. 60 hours base may be changed to 70, 80, 90, etc., but 60 is minimum because we will need to keep Project Manager available and episodically involve Software Developer, UI/UX Expert, DevOps Expert depending on the nature of your request. In case a Monthly Base Support client pays a monthly fee and an additional payment for extra hours over the monthly base.",
  },
  request: {
    title: "On Request support",
    text:
      "Works better if you do not need immediate team reaction and you can collect a package of 80 hours minimum. In this case, we consider your requests as a micro-project and we discover, estimate, staff and implement it. Just because we do not know when you may request the package, we do not keep a team waiting. We will need to stuff the micro-project with relevant team members, so the new team might be different from the previous one.",
  },
  premium: {
    title: "Premium project monitoring and support",
    text:
      "Have a higher monthly budget, and it allows us to involve a dedicated Quality Assurance specialist who will monitor User Behavior and continuously test your application. In addition, having 80 hours we will involve a DevOps expert who will along the way monitor your server infrastructure and react to server-connected issues almost immediately.",
  },
}

const PackageCard = ({ item }) => {
  const containerRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.addEventListener("click", closeDropdownOnClickOutside)
    document.addEventListener("keydown", closeTooltipWithEsc)

    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside)
      document.removeEventListener("keydown", closeTooltipWithEsc)
    }
  })

  const closeDropdownOnClickOutside = e => {
    if (containerRef && containerRef.current) {
      if (!containerRef.current.contains(e.target) && isOpen) {
        setIsOpen(false)
      }
    }
  }

  const closeTooltipWithEsc = e => {
    if (e.keyCode === 27 && isOpen) {
      setIsOpen(false)
    }
  }

  const toggleTooltip = e => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  return (
    <li className={styles.levelCard} ref={containerRef}>
      <button
        type="button"
        title="Learn more"
        id={`${item.id}-infoButton`}
        onClick={toggleTooltip}
        aria-expanded={isOpen ? "true" : "false"}
        aria-label={isOpen ? "Hide tooltip" : "Show tooltip"}
        className={`support-${item.id}`}
      >
        <div className={styles.levelCard__header}>
          <div>
            {item.costs}
            <sup>$</sup>
          </div>
          <div>
            <h3>{item.name}</h3>
          </div>
        </div>
        <ol className={styles.checkedList}>{item.basic}</ol>
        <div className={styles.levelCard__footer}>
          <div>{item.time}</div>
          <div className={styles.infoButtonWrapper}>
            <div className={cx(`support-${item.id}`, styles.infoButton)}>
              Learn more
            </div>
          </div>
        </div>
      </button>
      <div className={styles.infoTooltipWrapper}>
        <div
          className={cx(isOpen ? styles.tooltipOpen : styles.tooltip)}
          id={`${item.id}-infoTooltip`}
        >
          <h4>
            {TOOLTIPS[item.id].title}
            <button
              type="button"
              className={styles.buttonClose}
              title="Close tooltip"
              onClick={toggleTooltip}
            >
              Close
            </button>
          </h4>
          <p>{TOOLTIPS[item.id].text}</p>
        </div>
        {isOpen && <div className={styles.tooltipArrow} />}
      </div>
    </li>
  )
}

export default PackageCard

import React from "react"

export const TITLES = {
  expertise: {
    title: (
      <>
        our <strong>expertise</strong>
      </>
    ),
    strongText: "The Bespoke support package is designed based on your needs",
    uppercase: "Make sure we are on the same page",
    withIndent: false,
  },
  package: {
    title: (
      <>
        Options during the project <strong>support period</strong>
      </>
    ),
    strongText: "We offer support for LaSoft clients",
    uppercase: "Choose your support package",
    withIndent: true,
  },
}

export const LEVELS = [
  {
    id: "ongoing",
    name: "Ongoing support",
    costs: "2900",
    time: <>60 hours monthly&nbsp;*</>,
    basic: [
      <>
        <li>Regular Monthly Payment</li>
        <li>
          <strong>Minimum request volume in hours</strong> 1 hour
        </li>
        <li>
          <strong>Expected response time for initial</strong> 1 business day
        </li>
        <li>
          <strong>Expected fix delivery time</strong>
          2–4 business days
        </li>
        <li>
          <strong>Services available within the package</strong>
          Software development and bug fixing, UI/UX designs, Server
          Infrastructure Support, Support Request Coordination
        </li>
      </>,
    ],
  },
  {
    id: "request",
    name: "On Request support",
    costs: "3900",
    time: "Hours on demand",
    basic: [
      <>
        <li>Upfront payment when support is requested</li>
        <li>
          <strong>Minimum request volume in hours</strong>
          80 hours
        </li>
        <li>
          <strong>Expected response time for initial</strong>2 business days
        </li>
        <li>
          <strong>Expected fix delivery time</strong>
          3–5 weeks
        </li>
        <li>
          <strong>Services available within the package</strong>
          Software development and bug fixing, UI/UX designs, Server
          Infrastructure Support, Support Request Coordination
        </li>
      </>,
    ],
  },
  {
    id: "premium",
    name: "Premium project monitoring and support",
    costs: "7,000",
    time: "200 hours monthly",
    basic: [
      <>
        <li>Regular Monthly Payment</li>
        <li>
          <strong>Minimum request volume in hours</strong>1 hour
        </li>
        <li>
          <strong>Expected response time for initial</strong>1 business day
        </li>
        <li>
          <strong>Expected fix delivery time</strong>
          2–4 business days
        </li>
        <li>
          <strong>Additionally Ongoing</strong>
          <ul>
            <li>Quality assurance</li>
            <li>User behaviour monitoring</li>
            <li>Ongoing infrastructure monitoring</li>
          </ul>
        </li>
      </>,
    ],
  },
]

export const EXPERTISE = [
  {
    id: 1,
    name: "Ongoing Quality Assurance",
    text: [
      "Not clear requirements and expectations",
      "Finding defects early",
      "Testing end to end regression",
      "Not compromising quality",
      "Lack of resources",
    ],
    icon: "icon-protection",
  },
  {
    id: 2,
    name: "UI/UX Updates",
    text: [
      "Confusing forms",
      "Poor touch targets on tablet and mobile",
      "Poor-quality images",
      "Interface bugs",
    ],
    icon: "icon-pencil",
  },
  {
    id: 3,
    name: "Server infrastructure monitoring and support",
    text: [
      "High availability and scalability",
      "Geographical distribution",
      "The problems with the performance of the portals monitoring",
      "Customization: The multiple problems",
      "The problems with reporting",
      "Problems with cyber security",
      "Failures with updates",
    ],
    icon: "icon-headset",
  },
  {
    id: 4,
    name: "Bug Fixing and Minor Improvements",
    text: [
      "Bug report issues and problems",
      "Bug summary problems",
      "Ineffective QA environment",
      "Many bugs to be fixed",
    ],
    icon: "settings",
  },
  {
    id: 5,
    name: "Monitoring User Behavior",
    text: [
      "Problems with user behavior",
      "Slow web app speed",
      "Ineffective UEBA monitoring",
      "Problems with website or app usability",
    ],
    icon: "icon-users",
  },
]
